var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

exports = function (size) {
  return new LruCache(size);
};

function LruCache(size) {
  (this || _global).capacity = size | 0;
  (this || _global).map = Object.create(null);
  (this || _global).list = new DoublyLinkedList();
}

LruCache.prototype.get = function (key) {
  var node = (this || _global).map[key];
  if (node == null) return undefined;
  this.used(node);
  return node.val;
};

LruCache.prototype.set = function (key, val) {
  var node = (this || _global).map[key];

  if (node != null) {
    node.val = val;
  } else {
    if (!(this || _global).capacity) this.prune();
    if (!(this || _global).capacity) return false;
    node = new DoublyLinkedNode(key, val);
    (this || _global).map[key] = node;
    (this || _global).capacity--;
  }

  this.used(node);
  return true;
};

LruCache.prototype.used = function (node) {
  (this || _global).list.moveToFront(node);
};

LruCache.prototype.prune = function () {
  var node = (this || _global).list.pop();

  if (node != null) {
    delete (this || _global).map[node.key];
    (this || _global).capacity++;
  }
};

function DoublyLinkedList() {
  (this || _global).firstNode = null;
  (this || _global).lastNode = null;
}

DoublyLinkedList.prototype.moveToFront = function (node) {
  if ((this || _global).firstNode == node) return;
  this.remove(node);

  if ((this || _global).firstNode == null) {
    (this || _global).firstNode = node;
    (this || _global).lastNode = node;
    node.prev = null;
    node.next = null;
  } else {
    node.prev = null;
    node.next = (this || _global).firstNode;
    node.next.prev = node;
    (this || _global).firstNode = node;
  }
};

DoublyLinkedList.prototype.pop = function () {
  var lastNode = (this || _global).lastNode;

  if (lastNode != null) {
    this.remove(lastNode);
  }

  return lastNode;
};

DoublyLinkedList.prototype.remove = function (node) {
  if ((this || _global).firstNode == node) {
    (this || _global).firstNode = node.next;
  } else if (node.prev != null) {
    node.prev.next = node.next;
  }

  if ((this || _global).lastNode == node) {
    (this || _global).lastNode = node.prev;
  } else if (node.next != null) {
    node.next.prev = node.prev;
  }
};

function DoublyLinkedNode(key, val) {
  (this || _global).key = key;
  (this || _global).val = val;
  (this || _global).prev = null;
  (this || _global).next = null;
}

export default exports;