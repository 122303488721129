import _url from "url";
import _querystring from "querystring";
import _crypto from "crypto";
import _lru from "./lru";
import _process from "process";
import _buffer from "buffer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Buffer = _buffer.Buffer;
var process = _process;
var aws4 = exports,
    url = _url,
    querystring = _querystring,
    crypto = _crypto,
    lru = _lru,
    credentialsCache = lru(1000); // http://docs.amazonwebservices.com/general/latest/gr/signature-version-4.html

function hmac(key, string, encoding) {
  return crypto.createHmac("sha256", key).update(string, "utf8").digest(encoding);
}

function hash(string, encoding) {
  return crypto.createHash("sha256").update(string, "utf8").digest(encoding);
} // This function assumes the string has already been percent encoded


function encodeRfc3986(urlEncodedString) {
  return urlEncodedString.replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16).toUpperCase();
  });
}

function encodeRfc3986Full(str) {
  return encodeRfc3986(encodeURIComponent(str));
} // request: { path | body, [host], [method], [headers], [service], [region] }
// credentials: { accessKeyId, secretAccessKey, [sessionToken] }


function RequestSigner(request, credentials) {
  if (typeof request === "string") request = url.parse(request);
  var headers = request.headers = request.headers || {},
      hostParts = (!(this || _global).service || !(this || _global).region) && this.matchHost(request.hostname || request.host || headers.Host || headers.host);
  (this || _global).request = request;
  (this || _global).credentials = credentials || this.defaultCredentials();
  (this || _global).service = request.service || hostParts[0] || "";
  (this || _global).region = request.region || hostParts[1] || "us-east-1"; // SES uses a different domain from the service name

  if ((this || _global).service === "email") (this || _global).service = "ses";
  if (!request.method && request.body) request.method = "POST";

  if (!headers.Host && !headers.host) {
    headers.Host = request.hostname || request.host || this.createHost(); // If a port is specified explicitly, use it as is

    if (request.port) headers.Host += ":" + request.port;
  }

  if (!request.hostname && !request.host) request.hostname = headers.Host || headers.host;
  (this || _global).isCodeCommitGit = (this || _global).service === "codecommit" && request.method === "GIT";
}

RequestSigner.prototype.matchHost = function (host) {
  var match = (host || "").match(/([^\.]+)\.(?:([^\.]*)\.)?amazonaws\.com(\.cn)?$/);
  var hostParts = (match || []).slice(1, 3); // ES's hostParts are sometimes the other way round, if the value that is expected
  // to be region equals ‘es’ switch them back
  // e.g. search-cluster-name-aaaa00aaaa0aaa0aaaaaaa0aaa.us-east-1.es.amazonaws.com

  if (hostParts[1] === "es") hostParts = hostParts.reverse();

  if (hostParts[1] == "s3") {
    hostParts[0] = "s3";
    hostParts[1] = "us-east-1";
  } else {
    for (var i = 0; i < 2; i++) {
      if (/^s3-/.test(hostParts[i])) {
        hostParts[1] = hostParts[i].slice(3);
        hostParts[0] = "s3";
        break;
      }
    }
  }

  return hostParts;
}; // http://docs.aws.amazon.com/general/latest/gr/rande.html


RequestSigner.prototype.isSingleRegion = function () {
  // Special case for S3 and SimpleDB in us-east-1
  if (["s3", "sdb"].indexOf((this || _global).service) >= 0 && (this || _global).region === "us-east-1") return true;
  return ["cloudfront", "ls", "route53", "iam", "importexport", "sts"].indexOf((this || _global).service) >= 0;
};

RequestSigner.prototype.createHost = function () {
  var region = this.isSingleRegion() ? "" : "." + (this || _global).region,
      subdomain = (this || _global).service === "ses" ? "email" : (this || _global).service;
  return subdomain + region + ".amazonaws.com";
};

RequestSigner.prototype.prepareRequest = function () {
  this.parsePath();
  var request = (this || _global).request,
      headers = request.headers,
      query;

  if (request.signQuery) {
    (this || _global).parsedPath.query = query = (this || _global).parsedPath.query || {};
    if ((this || _global).credentials.sessionToken) query["X-Amz-Security-Token"] = (this || _global).credentials.sessionToken;
    if ((this || _global).service === "s3" && !query["X-Amz-Expires"]) query["X-Amz-Expires"] = 86400;
    if (query["X-Amz-Date"]) (this || _global).datetime = query["X-Amz-Date"];else query["X-Amz-Date"] = this.getDateTime();
    query["X-Amz-Algorithm"] = "AWS4-HMAC-SHA256";
    query["X-Amz-Credential"] = (this || _global).credentials.accessKeyId + "/" + this.credentialString();
    query["X-Amz-SignedHeaders"] = this.signedHeaders();
  } else {
    if (!request.doNotModifyHeaders && !(this || _global).isCodeCommitGit) {
      if (request.body && !headers["Content-Type"] && !headers["content-type"]) headers["Content-Type"] = "application/x-www-form-urlencoded; charset=utf-8";
      if (request.body && !headers["Content-Length"] && !headers["content-length"]) headers["Content-Length"] = Buffer.byteLength(request.body);
      if ((this || _global).credentials.sessionToken && !headers["X-Amz-Security-Token"] && !headers["x-amz-security-token"]) headers["X-Amz-Security-Token"] = (this || _global).credentials.sessionToken;
      if ((this || _global).service === "s3" && !headers["X-Amz-Content-Sha256"] && !headers["x-amz-content-sha256"]) headers["X-Amz-Content-Sha256"] = hash((this || _global).request.body || "", "hex");
      if (headers["X-Amz-Date"] || headers["x-amz-date"]) (this || _global).datetime = headers["X-Amz-Date"] || headers["x-amz-date"];else headers["X-Amz-Date"] = this.getDateTime();
    }

    delete headers.Authorization;
    delete headers.authorization;
  }
};

RequestSigner.prototype.sign = function () {
  if (!(this || _global).parsedPath) this.prepareRequest();

  if ((this || _global).request.signQuery) {
    (this || _global).parsedPath.query["X-Amz-Signature"] = this.signature();
  } else {
    (this || _global).request.headers.Authorization = this.authHeader();
  }

  (this || _global).request.path = this.formatPath();
  return (this || _global).request;
};

RequestSigner.prototype.getDateTime = function () {
  if (!(this || _global).datetime) {
    var headers = (this || _global).request.headers,
        date = new Date(headers.Date || headers.date || new Date());
    (this || _global).datetime = date.toISOString().replace(/[:\-]|\.\d{3}/g, ""); // Remove the trailing 'Z' on the timestamp string for CodeCommit git access

    if ((this || _global).isCodeCommitGit) (this || _global).datetime = (this || _global).datetime.slice(0, -1);
  }

  return (this || _global).datetime;
};

RequestSigner.prototype.getDate = function () {
  return this.getDateTime().substr(0, 8);
};

RequestSigner.prototype.authHeader = function () {
  return ["AWS4-HMAC-SHA256 Credential=" + (this || _global).credentials.accessKeyId + "/" + this.credentialString(), "SignedHeaders=" + this.signedHeaders(), "Signature=" + this.signature()].join(", ");
};

RequestSigner.prototype.signature = function () {
  var date = this.getDate(),
      cacheKey = [(this || _global).credentials.secretAccessKey, date, (this || _global).region, (this || _global).service].join(),
      kDate,
      kRegion,
      kService,
      kCredentials = credentialsCache.get(cacheKey);

  if (!kCredentials) {
    kDate = hmac("AWS4" + (this || _global).credentials.secretAccessKey, date);
    kRegion = hmac(kDate, (this || _global).region);
    kService = hmac(kRegion, (this || _global).service);
    kCredentials = hmac(kService, "aws4_request");
    credentialsCache.set(cacheKey, kCredentials);
  }

  return hmac(kCredentials, this.stringToSign(), "hex");
};

RequestSigner.prototype.stringToSign = function () {
  return ["AWS4-HMAC-SHA256", this.getDateTime(), this.credentialString(), hash(this.canonicalString(), "hex")].join("\n");
};

RequestSigner.prototype.canonicalString = function () {
  if (!(this || _global).parsedPath) this.prepareRequest();
  var pathStr = (this || _global).parsedPath.path,
      query = (this || _global).parsedPath.query,
      headers = (this || _global).request.headers,
      queryStr = "",
      normalizePath = (this || _global).service !== "s3",
      decodePath = (this || _global).service === "s3" || (this || _global).request.doNotEncodePath,
      decodeSlashesInPath = (this || _global).service === "s3",
      firstValOnly = (this || _global).service === "s3",
      bodyHash;

  if ((this || _global).service === "s3" && (this || _global).request.signQuery) {
    bodyHash = "UNSIGNED-PAYLOAD";
  } else if ((this || _global).isCodeCommitGit) {
    bodyHash = "";
  } else {
    bodyHash = headers["X-Amz-Content-Sha256"] || headers["x-amz-content-sha256"] || hash((this || _global).request.body || "", "hex");
  }

  if (query) {
    var reducedQuery = Object.keys(query).reduce(function (obj, key) {
      if (!key) return obj;
      obj[encodeRfc3986Full(key)] = !Array.isArray(query[key]) ? query[key] : firstValOnly ? query[key][0] : query[key];
      return obj;
    }, {});
    var encodedQueryPieces = [];
    Object.keys(reducedQuery).sort().forEach(function (key) {
      if (!Array.isArray(reducedQuery[key])) {
        encodedQueryPieces.push(key + "=" + encodeRfc3986Full(reducedQuery[key]));
      } else {
        reducedQuery[key].map(encodeRfc3986Full).sort().forEach(function (val) {
          encodedQueryPieces.push(key + "=" + val);
        });
      }
    });
    queryStr = encodedQueryPieces.join("&");
  }

  if (pathStr !== "/") {
    if (normalizePath) pathStr = pathStr.replace(/\/{2,}/g, "/");
    pathStr = pathStr.split("/").reduce(function (path, piece) {
      if (normalizePath && piece === "..") {
        path.pop();
      } else if (!normalizePath || piece !== ".") {
        if (decodePath) piece = decodeURIComponent(piece.replace(/\+/g, " "));
        path.push(encodeRfc3986Full(piece));
      }

      return path;
    }, []).join("/");
    if (pathStr[0] !== "/") pathStr = "/" + pathStr;
    if (decodeSlashesInPath) pathStr = pathStr.replace(/%2F/g, "/");
  }

  return [(this || _global).request.method || "GET", pathStr, queryStr, this.canonicalHeaders() + "\n", this.signedHeaders(), bodyHash].join("\n");
};

RequestSigner.prototype.canonicalHeaders = function () {
  var headers = (this || _global).request.headers;

  function trimAll(header) {
    return header.toString().trim().replace(/\s+/g, " ");
  }

  return Object.keys(headers).sort(function (a, b) {
    return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
  }).map(function (key) {
    return key.toLowerCase() + ":" + trimAll(headers[key]);
  }).join("\n");
};

RequestSigner.prototype.signedHeaders = function () {
  return Object.keys((this || _global).request.headers).map(function (key) {
    return key.toLowerCase();
  }).sort().join(";");
};

RequestSigner.prototype.credentialString = function () {
  return [this.getDate(), (this || _global).region, (this || _global).service, "aws4_request"].join("/");
};

RequestSigner.prototype.defaultCredentials = function () {
  var env = process.env;
  return {
    accessKeyId: env.AWS_ACCESS_KEY_ID || env.AWS_ACCESS_KEY,
    secretAccessKey: env.AWS_SECRET_ACCESS_KEY || env.AWS_SECRET_KEY,
    sessionToken: env.AWS_SESSION_TOKEN
  };
};

RequestSigner.prototype.parsePath = function () {
  var path = (this || _global).request.path || "/"; // S3 doesn't always encode characters > 127 correctly and
  // all services don't encode characters > 255 correctly
  // So if there are non-reserved chars (and it's not already all % encoded), just encode them all

  if (/[^0-9A-Za-z;,/?:@&=+$\-_.!~*'()#%]/.test(path)) {
    path = encodeURI(decodeURI(path));
  }

  var queryIx = path.indexOf("?"),
      query = null;

  if (queryIx >= 0) {
    query = querystring.parse(path.slice(queryIx + 1));
    path = path.slice(0, queryIx);
  }

  (this || _global).parsedPath = {
    path: path,
    query: query
  };
};

RequestSigner.prototype.formatPath = function () {
  var path = (this || _global).parsedPath.path,
      query = (this || _global).parsedPath.query;
  if (!query) return path; // Services don't support empty query string keys

  if (query[""] != null) delete query[""];
  return path + "?" + encodeRfc3986(querystring.stringify(query));
};

aws4.RequestSigner = RequestSigner;

aws4.sign = function (request, credentials) {
  return new RequestSigner(request, credentials).sign();
};

export default exports;